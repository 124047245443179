import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import {FullSlideshowLightboxExample, SlideshowLightboxExampleDoc, SlideshowLightboxExample, 
  PropsDoc, ThemesDoc, TutorialsList} from "../../components/Examples";
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import {SlideshowLightbox, Image} from "lightbox.js-react";
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import RelatedContent from '../../components/RelatedContent'
import DemoSidebar from '../../components/DemoSidebar'
import TutorialsSidebar from '../../components/TutorialsSidebar'


export default function Tutorials () {
  const images = [{title: "Photon", src: "https://source.unsplash.com/600x400/?pastel+cloud"}, 
  {title: "Inversion", src: "https://source.unsplash.com/500x400/?pastel+clouds"},
  {title: "Legion", src: "https://source.unsplash.com/600x400/?pastel+sky"},
  {title: "Inversion", src: "https://source.unsplash.com/600x400/?pastel"},
  {title: "Twenty Neue", src: "https://source.unsplash.com/600x400/?sky+pastel"},
  {title: "Geometrik", src: "https://source.unsplash.com/600x400/?dreamy+clouds"},         
  {title: "Achtung", src: "https://source.unsplash.com/600x400/?pastel+fruit"},];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>   <section className="section section--gradient font-inter bg_white">
  <Header overrideDarkTheme={true}/>
  <div className="pt-4">
  <main className="flex-grow">

      <section>
      <div className="max-w-6xl mx-auto px-2 sm:px-2">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Main content */}
          <div className="md:flex md:justify-between" data-sticky-container>

              <TutorialsSidebar /> 

              <div className="md:flex-grow  font-inter">

                {/* <Header /> */}
                <div className="text-lg text-gray-600">
                  <h1 className="h1 text-3xl text-gray-900 mb-4 font-bold">Tutorials</h1>

                  <p className="mb-8">
                    Welcome! In this section, we've provided links to a variety of tutorials in order to help you set-up 
                    Lightbox.js on your React or Next.js project. These tutorials showcase the various
                    applications of Lightbox.js, as well as the cool ways to customize the lightbox.
                    To get started with a tutorial, simply click on a link below!
                  </p>

                 <TutorialsList />

</div>

{/* Related content */}
  <RelatedContent />

{/* <Feedback /> */}

</div>

          </div>

          </div>
      </div>
      </section>

      </main>
  </div>

  <Footer overrideDarkTheme={true} />

</section>
  </Layout>
  
}